@charset "utf-8";
/* CSS Document */
/*border: 1px solid #000;*/

html, body, h1, h2, h3, h4, h5, h6, a, p, ol, ul, li, pre, code, address, variable, form, fieldset, blockquote, header, footer, section{ padding: 0; margin: 0; font-size: 100%; font-weight: normal; font-family: Georgia, "Times New Roman", Times, serif; }
a{ text-decoration: none;}
ul{ list-style: none;}
li{ display: inline;}
p#links a, button#search, #loggedIn a{
        text-shadow: -1px -1px 0px rgba(0,0,0,.8);
        filter: dropshadow(color=#000000, offx=-1, offy=-1);
        -moz-text-shadow: -1px -1px 0px rgba(0,0,0,.8);
        -webkit-text-shadow: -1px -1px 0px rgba(0,0,0,.8);
        -o-text-shadow: -1px -1px 0px rgba(0,0,0,.8);
        -ms-text-shadow: -1px -1px 0px rgba(0,0,0,.8);
}
img{ border: none; outline: none; text-decoration: none;}

html,body{ min-height: 100%;}

html{ background-color: #f6f6f6 !important;}

#container{ width: 980px; min-height: 500px; height: 100%; margin: 0 auto;}

header{ width: 100%; min-height: 100%; background-color: #fff;}
        #top{ width: 960px; min-height: 100px; margin: 0 auto; padding: 10px 0 0 0;}
                #topRight{ float: right;}
                        #loggedIn{ border: 1px solid #880000; width: 140px; height: 20px; padding: 20px 0 0 20px; margin: -30px 0 0 0;

                                background: #ce4242; /* Old browsers */
                                background: -moz-linear-gradient(top,  #ce4242 0%, #992828 100%); /* FF3.6+ */
                                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ce4242), color-stop(100%,#992828)); /* Chrome,Safari4+ */
                                background: -webkit-linear-gradient(top,  #ce4242 0%,#992828 100%);  /* Chrome10+,Safari5.1+ */
                                background: -o-linear-gradient(top,  #ce4242 0%,#992828 100%); /* Opera 11.10+ */
                                background: -ms-linear-gradient(top,  #ce4242 0%,#992828 100%); /* IE10+ */
                                background: linear-gradient(to bottom,  #ce4242 0%,#992828 100%); /* W3C */
                                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ce4242', endColorstr='#992828',GradientType=0 ); /* IE6-9 */

                                border-radius: 5px;

                        }
                                #loggedIn a{ color: #fff;}
                img#slogan{ margin: -50px 0 0 510px; padding: 0px;}
        nav{ width: 100%; min-height: 40px; background: url(images/navStrip.jpg) repeat-x; margin: 0px; padding: 0px; overflow: hidden;}
                #nav{ width: 960px; height: 40px; margin: 0 auto;}
                        p#links  {  padding: 10px 0 0 0; float: left;}
                        p#links a{ color: #e9e8e8; padding: 15px 10px; font-weight: 500;}
                        p#links a:hover{ background-color: #000;}

                        #navRight{ min-width: 219px; height: 100%; float: right; margin: 0px 0px 0px 0px; padding: 0px 30px 0px 0px}
                                form#selectForm{ margin: 6px 0 0 0;}
                                        button#search  { width: 60px; height: 25px; text-align: center; padding: 0px; margin: 0px; border: 1px solid #880000;  color: #fff; font-size: 10pt; cursor: pointer; float: left;

                                                background: #ce4242; /* Old browsers */
                                                background: -moz-linear-gradient(top,  #ce4242 0%, #992828 100%); /* FF3.6+ */
                                                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ce4242), color-stop(100%,#992828)); /* Chrome,Safari4+ */
                                                background: -webkit-linear-gradient(top,  #ce4242 0%,#992828 100%);  /* Chrome10+,Safari5.1+ */
                                                background: -o-linear-gradient(top,  #ce4242 0%,#992828 100%); /* Opera 11.10+ */
                                                background: -ms-linear-gradient(top,  #ce4242 0%,#992828 100%); /* IE10+ */
                                                background: linear-gradient(to bottom,  #ce4242 0%,#992828 100%); /* W3C */
                                                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ce4242', endColorstr='#992828',GradientType=0 ); /* IE6-9 */

                                                -webkit-border-radius: 6px;
                                                border-radius: 6px;

                                                box-shadow: 0px 1px 0px rgba(255,255,255,0.5);
                                                -moz-box-shadow: 0px 1px 0px rgba(255,255,255,0.5);
                                                -webkit-box-shadow: 0px 1px 0px rgba(255,255,255,0.5);
                                                -o-box-shadow: 0px 1px 0px rgba(255,255,255,0.5);
                                                -ms-box-shadow: 0px 1px 0px rgba(255,255,255,0.5);
                                        }
                                        input.searchBarPre{ width:200px; max-width: 200px; height: 20px; margin: 0 5px 0 5px; padding: 0 5px 2px0; color:gray; font-style:italic; font-size:x-small;}
                                        input.searchBarPost{ width:200px; max-width: 200px; height: 20px; margin: 0 5px 0 5px; padding: 0 5px 2px 0;}

/*

Start home page styles

*/
#construction {min-width:940px; background:url(images/constructionSite.png) repeat-x;  min-height: 372px; margin: 0px -11.5px; padding: 0px;}
        #constructionText {margin: 0px; padding: 120px 0 0 75px; width:475px;}
        #constructionText h1 {margin: 0px; padding: 0 0 50px 0; width:475px;font-family:"Arial Black", Gadget, sans-serif;font-size:30px;}
        #constructionPicture {margin: 0px; padding: 30px 50px 0 0; float:right;}

#detailSection {width:100%; height:250px; position:relative;  padding: 0 0 40px 0; }
        .detailBox{max-width:143px; min-width:130px; height:auto;  display:inline-table; margin: -50px auto auto auto; padding: 0px 5px; text-align: center;}
        #detailSection h3 {color:#ce4242;font-size:19px;padding:0 0 10px 0;}
        #smallTitle {margin:15px 0 0 0;}
        #detailSection p {font-size:13px;}
        #detailSection button {width: 100px; height: 25px; text-align: center; padding: 0px; margin: 20px -50px;  color: #fff; font-size: 8pt; cursor: pointer; position:absolute; bottom:0; border-radius: 5px;

                background: rgb(200,200,200); /* Old browsers */
                /* IE9 SVG, needs conditional override of 'filter' to 'none' */
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2M4YzhjOCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijc5JSIgc3RvcC1jb2xvcj0iIzc5Nzk3OSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM2NDY0NjQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
                background: -moz-linear-gradient(top,  rgba(200,200,200,1) 0%, rgba(121,121,121,1) 79%, rgba(100,100,100,1) 100%); /* FF3.6+ */
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(200,200,200,1)), color-stop(79%,rgba(121,121,121,1)), color-stop(100%,rgba(100,100,100,1))); /* Chrome,Safari4+ */
                background: -webkit-linear-gradient(top,  rgba(200,200,200,1) 0%,rgba(121,121,121,1) 79%,rgba(100,100,100,1) 100%); /* Chrome10+,Safari5.1+ */
                background: -o-linear-gradient(top,  rgba(200,200,200,1) 0%,rgba(121,121,121,1) 79%,rgba(100,100,100,1) 100%); /* Opera 11.10+ */
                background: -ms-linear-gradient(top,  rgba(200,200,200,1) 0%,rgba(121,121,121,1) 79%,rgba(100,100,100,1) 100%); /* IE10+ */
                background: linear-gradient(to bottom,  rgba(200,200,200,1) 0%,rgba(121,121,121,1) 79%,rgba(100,100,100,1) 100%); /* W3C */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c8c8c8', endColorstr='#646464',GradientType=0 ); /* IE6-8 */


        }

#trialBox {min-width:940px; background: #b6b6b6; height:40px;  margin: 15px -10px; padding:10px 0 0 0; text-align:center; font-family:"Times NewRoman", Times, serif}
        #android {height:30px;display:inline; padding:0;margin:0;}
        #firstElement {margin:10px 0;}
        #trialBox button { width: 100px; height: 25px; text-align: center; padding: 0px 10px; margin: 0px 10px; border: 1px solid #fff;  color: #fff; font-size: 10pt; cursor: pointer; display:inline;

                background: #ce4242; /* Old browsers */
                background: -moz-linear-gradient(top,  #ce4242 0%, #992828 100%); /* FF3.6+ */
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ce4242), color-stop(100%,#992828)); /* Chrome,Safari4+ */
                background: -webkit-linear-gradient(top,  #ce4242 0%,#992828 100%);  /* Chrome10+,Safari5.1+ */
                background: -o-linear-gradient(top,  #ce4242 0%,#992828 100%); /* Opera 11.10+ */
                background: -ms-linear-gradient(top,  #ce4242 0%,#992828 100%); /* IE10+ */
                background: linear-gradient(to bottom,  #ce4242 0%,#992828 100%); /* W3C */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ce4242', endColorstr='#992828',GradientType=0 ); /* IE6-9 */

                -webkit-border-radius: 6px;
                border-radius: 6px;

                box-shadow: 0px 1px 0px rgba(255,255,255,0.5);
                -moz-box-shadow: 0px 1px 0px rgba(255,255,255,0.5);
                -webkit-box-shadow: 0px 1px 0px rgba(255,255,255,0.5);
                -o-box-shadow: 0px 1px 0px rgba(255,255,255,0.5);
                -ms-box-shadow: 0px 1px 0px rgba(255,255,255,0.5);
        }

#redBox {min-width:940px; height:195px;  margin: 0px -10px; padding:0px auto;}
        #redBox h2 {font-family:"Arial Black", Gadget, sans-serif;font-size:20px;margin:20px 0 15px 0 ;}
        .infoBox img {border-radius: 6px;}
        .infoBox p {font-size:14px; font-style:italic;}
        .infoBox {max-width:143px; height:auto;  display:inline-table; margin: 0px auto auto auto; padding: 0px 5px; text-align: center;}

#testimonialBox {min-width:940px; height:200px;  margin: 0px -10px; padding:0px auto;}
        .testimonialDetail {width:200px; margin: 0px 50px; display:inline-table;}
        #testimonialBox h2 {font-family:"Arial Black", Gadget, sans-serif;font-size:16px;margin:20px 0 15px 0;font-weight:100;}
        #testimonialBox  p{font-size:12px; font-style:italic;font-family:Verdana, Geneva, sans-serif;padding:0 0 5px 0;}
        #testimonialBox h4 {font-weight:bold; font-size:14px;}

/*

End home page styles

*/
/*

Start FAQ styles

*/
#faqNav {min-width:150px;padding:30px 0 0 0;margin:10px 0 0 0;display:inline;float:left;position:absolute;}
        #faqNav h3 {margin:10px 0 10px 0;padding:22px 0 0 0;font-size:16px;color:#ce4242;display:inline;}
        #faqNav a {margin:0;padding:15px 0 0 0;font-size:16px;color:#ce4242;display:inline;}
        #navImage {min-height:40px;height:40px;padding:0;margin:0px auto;vertical-align:middle;display:inline;clear:both;}
#faqSection {min-width:700px; margin: 10px 0 0 220px; padding:5px 0 0 0;float:right;display:block; height:auto;}
        #titleFAQ {margin:0 0 0 10px;font-size:32px;}
        #faqSection h1 {padding:15px 0 0 0; margin:0 0 0 10px;font-size:40px;color:#ce4242;display:inline;}
        #headerImage {height:60px;padding:0px 5px 10px 5px;margin:0 0 0 10px;vertical-align:middle;}
.faqQuestions {width:700px; min-height:30px; height:auto;background: #b6b6b6;  margin: 0px 0px 5px 0px; padding:5px auto;vertical-align:center;border-radius:10px;border: 1px solid #000;}
        .faqQuestions h2 {font-size:17px;color:#000;display:inline;padding:10px 0 0 10px;}
.faqAnswer {width:698px; min-height:130px;height:auto;background: #fff;background:url(images/constructionSite2.png) no-repeat; background-size:contain; background-position:center bottom; margin: -5px 0 5px 0; padding:5px 0 20px 0;border: 1px solid #b6b6b6; border-radius:6px;color:#000}
        .faqAnswer p {text-indent: 10px;padding:10px 15px 30px 15px;}

/*

End FAQ styles

*/
/*

Start Feature styles

*/
#detailSectionFeature {width:100%; height:80px; position:relative;  padding: 0 0 40px 0; }
        #detailSectionFeature h3 {color:#ce4242;font-size:17px;padding:0 0 5px 0;}
        .detailBoxFeature{max-width:143px; min-width:110px; height:auto;  display:inline-table; margin: -50px auto auto auto; padding: 0px 0px; text-align: center;}
        .detailBoxFeature img {width:110px;}

.selectLine {display: none;margin-left: auto;margin-right: auto;margin-bottom:25px;}

.leftFeature {width:400px; min-height:628px;display:inline-block;margin:0px 0 0 0;padding:0; vertical-align:top;}
        .leftFeatureText {width:300px;margin:0 auto;padding:0 0 0 0;}
                .leftFeatureText h2 {color:#ce4242;font-size:22px;padding:20px auto 5px auto;margin:10px auto;text-align:center;display:inline;vertical-align:10px;}
                .leftFeatureText img {width:60px;display:inline;margin:10px 0 0 30px;text-align:center;}
                .leftFeatureText p {font-size:14px;margin:20px 0 0 0;line-height: 25px;text-align:center}
                .leftFeatureText li {color:#ce4242;font-size:14px;padding:0;margin:15px auto;text-align:center;list-style-type:square; font-weight:bold;}
                .leftFeatureText h3 {font-size:17px;display:inline;}
                .leftFeatureText hr {margin:15px 0;}
                .leftFeatureText button { width: 85px; height: 25px; text-align: center; padding: 0px 10px; margin: 0px 10px; border: 1px solid #fff;  color: #fff; font-size: 10pt; cursor: pointer; display:inline;

                background: #ce4242; /* Old browsers */
                background: -moz-linear-gradient(top,  #ce4242 0%, #992828 100%); /* FF3.6+ */
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ce4242), color-stop(100%,#992828)); /* Chrome,Safari4+ */
                background: -webkit-linear-gradient(top,  #ce4242 0%,#992828 100%);  /* Chrome10+,Safari5.1+ */
                background: -o-linear-gradient(top,  #ce4242 0%,#992828 100%); /* Opera 11.10+ */
                background: -ms-linear-gradient(top,  #ce4242 0%,#992828 100%); /* IE10+ */
                background: linear-gradient(to bottom,  #ce4242 0%,#992828 100%); /* W3C */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ce4242', endColorstr='#992828',GradientType=0 ); /* IE6-9 */

                -webkit-border-radius: 6px;
                border-radius: 6px;

                box-shadow: 0px 1px 0px rgba(255,255,255,0.5);
                -moz-box-shadow: 0px 1px 0px rgba(255,255,255,0.5);
                -webkit-box-shadow: 0px 1px 0px rgba(255,255,255,0.5);
                -o-box-shadow: 0px 1px 0px rgba(255,255,255,0.5);
                -ms-box-shadow: 0px 1px 0px rgba(255,255,255,0.5);
        }
.rightFeature {width:520px; min-height:628px;display:inline-block;background:url(images/ipad.png) no-repeat;margin:0;padding:0;background-position:center bottom;}
        /*#innerRightFeature {z-index: -1;width:350px; min-height:470px;background:url(images/TrainingScreen.png) no-repeat;overflow:scroll;margin:50px 0 0 80px; background-size:350px 470px;}*/

.ipadtop, .ipadleft, .ipadright, .ipadbottom {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
}

.ipadtop{
  width: 100%;
  height: 20px;
  background:url(images/ipad_top.png);
}

.ipadbottom {
  top: auto;
  bottom: 0;
  background:url(images/ipad_bottom.png);
  width: 100%;
  height: 20px;
}

.ipadleft{
  height: 100%;
  width: 20px;
  background:url(images/ipad_left.png);
}

.ipadright {
  left: auto;
  right: 0;
  background:url(images/ipad_right.png);
  height: 100%;
  width: 20px;
}

.ipadcontent {
  position: relative;
  z-index: 1;
  margin: 50px 0 0 90px;
  width:350px;
  height:460px;
  overflow-y:scroll;
  overflow-x:hidden;
}

.ipadcontent img { width:330px;}

.whoUsesImage {
        width:15%;
        margin-right:4%;
        margin-top:20px;
        margin-bottom:25px;
        vertical-align:middle;
}

/*

End Feature styles

*/


section{ width: 100%; height: 100%; min-height: 500px; background: url(images/bodyStrip.png) repeat-y;  clear: left; padding: 0 0 40px 0;}
        #sectionContent{ width: 940px; min-height: 500px; height: 100%; margin: 0 auto;}

footer{ width: 980px; height: 50px; background: url(images/footerBG.png) no-repeat; padding-top: -40px;}
        footer p{ color: #fff; font-family: Arial, Helvetica, sans-serif; font-size: 8pt; text-align: center; padding: 12px 0 0 0;}