@charset "utf-8";

#info a,
#sidebar a {
	color: #257BBA;
	font-weight: bold;
}

#info a:hover,
a#back:hover {
	text-decoration: underline;
}

embed {
	border: 1px solid #000;
}

.addText {
	margin: 0 0 10px 0;
}

.addText a {
	color: #000;
	font-weight: bold;
	border: 1px solid #393;
	padding: 4px;
	border-radius: 8px;
	background: linear-gradient(to bottom, #b7dd56 1%, #589639 100%);
}

.addText a:hover {
	text-decoration: underline;
}

table {
	margin: 0 auto;
}

table a {
	color: #03F;
	text-decoration: none;
}

th {
	border: 1px solid #000;
}

img.addButton,
img#centerButton,
img.workerEdit,
img.tableButton {
	width: 25px;
	margin: 5px;
	vertical-align: top;
}

img#limit,
img#leftButton {
	max-width: 220px;
	width: 175px;
}

h1,
h2,
h3 {
	font-weight: bold;
	font-family: Tahoma, Geneva, sans-serif;
	font-size: 20pt;
}

#sidebar {
	width: 170px;
	border: 1px solid #000;
	float: right;
	padding: 25px;
	background: #fff;
	box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.6);
}

#message p,
#good,
#bad {
	text-align: center;
	font-weight: bold;
	color: #f00;
}

#good {
	color: #0f0;
}

fieldset,
#info {
	border: 1px solid #000;
	padding: 25px;
	border-radius: 20px;
	background: #fff;
	max-width: 57%;
	box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.6);
}

#renew,
#email {
	float: right;
	width: 60px;
	height: 20px;
	text-align: center;
	color: #fff;
	font-size: 10pt;
	cursor: pointer;
	background: linear-gradient(to bottom, #ce4242 0%, #992828 100%);
	border-radius: 6px;
	box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
}

#renew:active,
button#search:active,
#email:active {
	background: linear-gradient(to bottom, #992828 0%, #ce4242 100%);
}

#construction {
	min-width: 940px;
	background: url(images/constructionSite.png) repeat-x;
	min-height: 372px;
	margin: 0px -11.5px;
	padding: 0px;
}

#constructionText {
	margin: 0px;
	padding: 120px 0 0 75px;
	width: 475px;
}

#constructionText h1 {
	margin: 0px;
	padding: 0 0 50px 0;
	width: 475px;
	font-family: "Arial Black", Gadget, sans-serif;
	font-size: 30px;
}

#constructionPicture {
	margin: 0px;
	padding: 30px 50px 0 0;
	float: right;
}

detailSection {
	width: 100%;
	height: 250px;
	position: relative;
	padding: 0 0 40px 0;
}

.detailBox {
	max-width: 143px;
	min-width: 130px;
	height: auto;
	display: inline-table;
	margin: -50px auto auto auto;
	padding: 0px 5px;
	text-align: center;
}

#detailSection h3 {
	color: #ce4242;
	font-size: 19px;
	padding: 0 0 10px 0;
}

#smallTitle {
	margin: 15px 0 0 0;
}

#detailSection p {
	font-size: 13px;
}

#detailSection button {
	width: 100px;
	height: 25px;
	text-align: center;
	padding: 0px;
	margin: 20px -50px;
	color: #fff;
	font-size: 8pt;
	cursor: pointer;
	position: absolute;
	bottom: 0;
	border-radius: 5px;

	background: rgb(200, 200, 200);
	/* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2M4YzhjOCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijc5JSIgc3RvcC1jb2xvcj0iIzc5Nzk3OSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM2NDY0NjQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top, rgba(200, 200, 200, 1) 0%, rgba(121, 121, 121, 1) 79%, rgba(100, 100, 100, 1) 100%);
	/* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(200, 200, 200, 1)), color-stop(79%, rgba(121, 121, 121, 1)), color-stop(100%, rgba(100, 100, 100, 1)));
	/* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, rgba(200, 200, 200, 1) 0%, rgba(121, 121, 121, 1) 79%, rgba(100, 100, 100, 1) 100%);
	/* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, rgba(200, 200, 200, 1) 0%, rgba(121, 121, 121, 1) 79%, rgba(100, 100, 100, 1) 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, rgba(200, 200, 200, 1) 0%, rgba(121, 121, 121, 1) 79%, rgba(100, 100, 100, 1) 100%);
	/* IE10+ */
	background: linear-gradient(to bottom, rgba(200, 200, 200, 1) 0%, rgba(121, 121, 121, 1) 79%, rgba(100, 100, 100, 1) 100%);
	/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c8c8c8', endColorstr='#646464', GradientType=0);
	/* IE6-8 */


}

#trialBox {
	min-width: 940px;
	background: #b6b6b6;
	height: 40px;
	margin: 15px -10px;
	padding: 10px 0 0 0;
	text-align: center;
	font-family: "Times NewRoman", Times, serif
}

#android {
	height: 30px;
	display: inline;
	padding: 0;
	margin: 0;
}

#firstElement {
	margin: 10px 0;
}

#trialBox button {
	width: 100px;
	height: 25px;
	text-align: center;
	padding: 0px 10px;
	margin: 0px 10px;
	border: 1px solid #fff;
	color: #fff;
	font-size: 10pt;
	cursor: pointer;
	display: inline;

	background: #ce4242;
	/* Old browsers */
	background: -moz-linear-gradient(top, #ce4242 0%, #992828 100%);
	/* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ce4242), color-stop(100%, #992828));
	/* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #ce4242 0%, #992828 100%);
	/* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #ce4242 0%, #992828 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, #ce4242 0%, #992828 100%);
	/* IE10+ */
	background: linear-gradient(to bottom, #ce4242 0%, #992828 100%);
	/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ce4242', endColorstr='#992828', GradientType=0);
	/* IE6-9 */

	-webkit-border-radius: 6px;
	border-radius: 6px;

	box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
	-moz-box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
	-webkit-box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
	-o-box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
	-ms-box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
}

#redBox {
	min-width: 940px;
	height: 195px;
	margin: 0px -10px;
	padding: 0px auto;
}

#redBox h2 {
	font-family: "Arial Black", Gadget, sans-serif;
	font-size: 20px;
	margin: 20px 0 15px 0;
}

.infoBox img {
	border-radius: 6px;
}

.infoBox p {
	font-size: 14px;
	font-style: italic;
}

.infoBox {
	max-width: 143px;
	height: auto;
	display: inline-table;
	margin: 0px auto auto auto;
	padding: 0px 5px;
	text-align: center;
}

#testimonialBox {
	min-width: 940px;
	height: 200px;
	margin: 0px -10px;
	padding: 0px auto;
}

.testimonialDetail {
	width: 200px;
	margin: 0px 50px;
	display: inline-table;
}

#testimonialBox h2 {
	font-family: "Arial Black", Gadget, sans-serif;
	font-size: 16px;
	margin: 20px 0 15px 0;
	font-weight: 100;
}

#testimonialBox p {
	font-size: 12px;
	font-style: italic;
	font-family: Verdana, Geneva, sans-serif;
	padding: 0 0 5px 0;
}

#testimonialBox h4 {
	font-weight: bold;
	font-size: 14px;
}


blockquote {
	background: #f9f9f9;
	border-left: 10px solid #ccc;
	margin: 0.5em 10px;
	padding: 0.5em 10px;
	quotes: "\201C" "\201D" "\2018" "\2019";
	width: 600px;
	text-align: left;
}

blockquote:before {
	color: #ccc;
	content: open-quote;
	font-size: 4em;
	line-height: 0.1em;
	margin-right: 0.25em;
	vertical-align: -0.4em;
}

blockquote p {
	display: inline;
	text-indent: 2em;
}

.faqAnswer {
    display: none;
}

.faqAnswer.show {
    display: block;
}
